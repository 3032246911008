<template>
  <div class="landing_1122">
    <section>
      <nav>
        <img src="/svgs/ned_logo_light.svg" alt="" class="logo_1" />
        <img src="/svgs/logo_2.svg" alt="" class="logo_2" />
      </nav>

      <div class="caption-wrapper">
        <div class="caption">
          <h1>
            NED students have <br />
            <span class="text-accent text-s-lg">10% DISCOUNT</span> <br />
            on all Spanish courses in
            <span class="text-accent">Madrid</span> or
            <span class="text-accent">Barcelona</span>
          </h1>
          <button class="btn" @click="goToForm">I WANT TO KNOW MORE!</button>
        </div>
      </div>
    </section>

    <section>
      <div class="container">
        <div class="row">
          <div class="col-lg-6 mb-4 d-flex flex-column">
            <div class="photos waves-left-top">
              <img src="/imgs/landing_1122/madrid_1.png" alt="" />
              <img src="/imgs/landing_1122/madrid_2.png" alt="" />
              <div class="location-tag">MADRID</div>
            </div>

            <div class="card card-gradient flex-1">
              <div class="card-header">
                <h3>
                  LIVING IN <br />
                  MADRID
                </h3>
              </div>
              <div class="card-body">
                <p>
                  Is simply living in one of the most charming and well-known
                  cities in the world.
                </p>
                <p>
                  It's a multicultural and international capital with one of the
                  highest quality urban life and some of the most famous museums
                  in Europe.
                </p>
              </div>
            </div>
          </div>

          <div class="col-lg-6 mb-4 d-flex flex-column">
            <div class="photos waves-right-bottom">
              <img src="/imgs/landing_1122/barcelona_1.png" alt="" />
              <img src="/imgs/landing_1122/barcelona_2.png" alt="" />
              <div class="location-tag">BARCELONA</div>
            </div>

            <div class="card card-gradient flex-1">
              <div class="card-header">
                <h3>
                  LIVING IN <br />
                  BARCELONA
                </h3>
              </div>
              <div class="card-body">
                <p>
                  Means experiencing one of the most popular destinations in the
                  world.
                </p>
                <p>
                  It is like living a dream in a city full of culture, good food
                  and an incredible atmosphere, with a lifestyle that mixes a
                  big city with magnificent beaches, music, art and sports.
                </p>
              </div>
            </div>
          </div>
        </div>

        <button class="btn my-6" @click="goToForm">I WANT TO KNOW MORE!</button>
      </div>
    </section>

    <section>
      <div class="container">
        <h2 class="mb-0">CHOOSE</h2>
        <h2 class="text-accent mb-6">WHAT SUITS YOU BEST</h2>

        <div class="waves-left-center">
          <div class="card text-s-lg mb-5">
            <h4>Short term courses from 1 week</h4>
            <p>For those who want to enjoy their holidays in Spain</p>
          </div>
          <div class="card text-s-lg">
            <h4>Long term courses</h4>
            <p>
              In case the student finishes their course and wants to move to
              another country
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="form">
      <div class="container">
        <h3 class="text-accent">Excited? We are!</h3>
        <h5>Don't miss out on this great opportunity!</h5>

        <div class="card card-white text-center mt-6">
          <div class="card-body p-4">
            <div class="pt-4 px-md-7" id="form" />
          </div>
        </div>
      </div>
    </section>

    <section class="footer">
      <nav>
        <img src="/svgs/ned_logo_light.svg" alt="" class="logo_1" />
        <img src="/svgs/logo_2.svg" alt="" class="logo_2" />
      </nav>
    </section>
  </div>
</template>

<script>
export default {
  beforeUnmount() {},
  mounted() {},
  methods: {
    goToForm() {
      document.querySelector("#form").scrollIntoView();
    },
  },
};
</script>
